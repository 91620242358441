import { QueryClient, useQuery } from "@tanstack/vue-query";
import QueryKeys from "@/vue-query/QueryKeys";
import { useProjectApi } from "../api/useProjectApi";
import {
  AvailableLocationsDto,
  DeploymentProvisioningStatusDto,
} from "~/api/contracts/models";
import { useProvisioningApi } from "../api/useProvisioningApi";

export function useAvailableLocationsQuery() {
  const client = useProvisioningApi();

  return useQuery(
    [QueryKeys.AvailableLocations],
    () => client.getAvailableLocations(),
    {
      refetchInterval: 15 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
}

export function useAvailableLocationsQueryPrefetch(
  queryClient: QueryClient
): Promise<void> {
  const client = useProvisioningApi();
  return queryClient.prefetchQuery<AvailableLocationsDto>(
    [QueryKeys.AvailableLocations],
    () => client.getAvailableLocations()
  );
}
