import { ContentType, HttpClient, RequestParams } from "./base/BaseHttpClient";
import {
  AvailableLocationsDto,
  BeginDeploymentProvisioningRequest,
  MarkProvisioningAsCompletedRequest,
} from "./contracts/models";

export class ProvisioningApi<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Provisioning
   * @name GetAvailableLocations
   * @request GET:/api/provisioning/available-locations
   * @response `200` `AvailableLocationsDto` OK
   */
  getAvailableLocations = (params: RequestParams = {}) =>
    this.http
      .request<AvailableLocationsDto, any>({
        path: `/api/provisioning/available-locations`,
        method: "GET",
        credentials: "include",
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Provisioning
   * @name BeginProvisioning
   * @request POST:/api/provisioning
   * @response `200` `string` OK
   */
  beginProvisioning = (data: BeginDeploymentProvisioningRequest, params: RequestParams = {}) =>
    this.http
      .request<string, any>({
        path: `/api/provisioning`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      })
      .then((m) => m.data);
  /**
   * No description
   *
   * @tags Provisioning
   * @name MarkProvisioningAsCompleted
   * @request POST:/api/provisioning/mark-as-completed
   * @response `200` `void` OK
   */
  markProvisioningAsCompleted = (data: MarkProvisioningAsCompletedRequest, params: RequestParams = {}) =>
    this.http
      .request<void, any>({
        path: `/api/provisioning/mark-as-completed`,
        method: "POST",
        credentials: "include",
        body: data,
        type: ContentType.Json,
        ...params,
      })
      .then((m) => m.data);
}
