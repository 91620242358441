import { QueryClient, useQuery } from "@tanstack/vue-query";
import QueryKeys from "@/vue-query/QueryKeys";
import { useProjectApi } from "../api/useProjectApi";
import { DeploymentProvisioningStatusDto } from "~/api/contracts/models";

export function useDeploymentProvisioningStatusQuery(projectId: Ref<string>) {
  const client = useProjectApi();

  const enabled = computed(() => !!projectId.value);

  return useQuery(
    [QueryKeys.DeploymentProvisioningStatus, projectId],
    () => client.getDeploymentProvisioningStatus(projectId.value),
    {
      refetchInterval: 5 * 1000,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: true,
      enabled,
    }
  );
}

export function useDeploymentProvisioningStatusQueryPrefetch(
  queryClient: QueryClient,
  projectId: Ref<string>
): Promise<void> {
  const client = useProjectApi();
  return queryClient.prefetchQuery<DeploymentProvisioningStatusDto>(
    [QueryKeys.DeploymentProvisioningStatus, projectId],
    () => client.getDeploymentProvisioningStatus(projectId.value)
  );
}
